import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ISearchClaimVM } from 'src/app/models/search-claim.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { IPager } from '../../../shared/models/pager.model';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '../../../shared/services/configuration.service';
import { SecurityService } from '../../../shared/services/security.service';
import { SearchClaimService } from './search-claim.service';
import { DataTableDirective } from 'angular-datatables';
import { LookupService } from '../../../shared/services/lookup.service';
import { Router } from '@angular/router';
import { ISearchClaimResult } from './search-claim-result.model';
import * as moment from 'moment';
import { StorageService } from '../../../shared/services/storage.service';
import * as _ from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { enumHandlingOrganization, enumUserRole } from '../../../shared/models/common.enum';

@Component({
  selector: 'app-search-claim',
  templateUrl: './search-claim.component.html',
  styleUrls: ['./search-claim.component.css']
})


export class SearchClaimComponent implements OnDestroy, OnInit {

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  public searchClaimVM: ISearchClaimVM = new ISearchClaimVM();
  public searchClaimResult: ISearchClaimResult[];
  public dtOptions: any = {};
  public paginationInfo: IPager;
  public authenticated: boolean = false;
  public authSubscription: Subscription;
  public errorReceived: boolean;
  public dtTrigger: Subject<any>;
  public selfComponent: any = this;
  public isHidden: boolean = true;
  public searching: boolean;
  public handlingOrganisations: any[];
  public LineofBusinessLookup: any[];
  public ClaimStatusLookups: any[];
  public ClaimTypeLookups: any[];
  public claimHandlers: any[];
  public mmiClaimHandlers: any[];
  public isInitialised: boolean = false;
  public loggedInUserOrganisationId: number;
  public isMMIUser: boolean = true;
  public locationsLookup: any[];
  public perpetratorsLookup: any[];
  public ClaimStageLookups: any[];
  public nextKeyDates: any[];
  public occupationTypeLookup: any[];
  public ClaimantSolicitorsLookup: any[];
  public capeProducts: any[];
  public mmiSupervisorLookup: any[];
  public isHiddenMMISupervisor: boolean = false;
  public displayedColumns: any[];
  public isMMIClaimAndClaimFinanceSuperUser: boolean = false;

  @ViewChild('btnSearchClaims') btnSearchClaims: ElementRef;

  constructor(private service: SearchClaimService,
    private configurationService: ConfigurationService,
    private securityService: SecurityService,
    private lookupService: LookupService,
    private storageService: StorageService,
    private router: Router) {
    this.dtTrigger = new Subject();
  }

  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: false,
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  mmiKeyContactDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'claimsHandlers',
    enableCheckAll: false,
    itemsShowLimit: 1,
    allowSearchFilter: true
  };

  aggregatedDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'text',
    enableCheckAll: false,
    selectAllText: "All",
    itemsShowLimit: 1,
    allowSearchFilter: true,
    maxHeight: 150,
  };

  ngOnInit(): void {

    let userDetail = this.securityService.getUserData();
    this.isMMIClaimAndClaimFinanceSuperUser = ((userDetail.organisationId == 1) && ((userDetail.userRoleId == enumUserRole.Claims) || ((userDetail.userRoleId == enumUserRole.ClaimsAndFinance) && (userDetail.superUser))));
    if (this.isMMIClaimAndClaimFinanceSuperUser == false) {
      this.isHiddenMMISupervisor = true;
    }

    this.loggedInUserOrganisationId = this.securityService.getUserData().organisationId;
    if (this.loggedInUserOrganisationId != null && this.loggedInUserOrganisationId != 1) {
      this.searchClaimVM.handlingOrganisation = this.loggedInUserOrganisationId;
      this.isMMIUser = false;
    }

    if (this.isHiddenMMISupervisor == false) {
      this.displayedColumns = [
        { title: 'Claim Id', data: 'claimId' },
        { title: 'Policy Id', data: 'policyId' },
        { title: 'MMI Claim Reference', data: 'mmiClaimsReference' },
        { title: 'Claimant Name', data: 'claimantName' },

        { title: 'Occupation', data: 'occupation' },
        { title: 'Claimant Solicitor', data: 'claimantSolicitor' },


        { title: 'Insured Title', data: 'insuredTitle' },
        { title: 'Handling Organisation Claim Ref', data: 'claimReference' },

        { title: 'Next Key Date', data: 'nextKeyDateSelected' },
        { title: 'Next Key Date Diary', data: 'nextKeyDateDiary', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },

        { title: 'Policy Number', data: 'policyNumber' },
        { title: 'Policyholder Name', data: 'creditorName' },
        { title: 'Policyholder ID', data: 'creditorId' },
        { title: 'Claim Type', data: 'claimType' },

        { title: 'MMI Valuation', data: 'mmiValuation', render: $.fn.dataTable.render.number(',', '.', 2, '£') },

        { title: 'MMI Paid', data: 'totalPayments', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
        { title: 'MMI Reserve', data: 'totalReserve', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
        { title: 'MMI Incurred', data: 'totalIncurred', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
        { title: 'Reserve Last Reviewed', data: 'reserveLastReviewed', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'Diary Date', data: 'diaryDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'Claim Status', data: 'claimStatus' },
        { title: 'Linked References', data: 'linkedReferences' },
        { title: 'Line Of Business', data: 'lineofBusiness' },
        { title: 'Handling Organization', data: 'handlingOrganisation' },
        { title: 'Claim Handler', data: 'claimHandler' },
        { title: 'Claimant DOB', data: 'claimantDOB', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'MMI Review Date', data: 'mmiReviewDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'MMI Key Contact', data: 'mmiKeyContact' },
        { title: 'MMI Supervisor', data: 'mmiSupervisor' },
        { title: 'Claimant NINO', data: 'claimantNINO' },
        { title: 'Location', data: 'location', render: function (data) { return data == null ? "" : data.substr(1) } },
        { title: 'Perpetrator', data: 'perpetrator', render: function (data) { return data == null ? "" : data.substr(1) } },
        { title: 'Open Date', data: 'openDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'Settled Date', data: 'settledDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'Date Last Re-Opened', data: 'dateLastReopened', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'DA Status', data: 'daStatus' },
        { title: 'Charging Rate', data: 'chargingRate' },
        { title: 'Litigated Claim', data: 'litigated' },
        { title: 'Contribution Claim', data: 'contributionClaim' },

        { title: 'Jurisdiction', data: 'jurisdiction' },
        { title: 'Cape Products', data: 'capeProduct' },
        { title: 'Provisional Damages Settlement', data: 'provisionalDamagesSettlement' },

        { title: 'Claim Stage', data: 'defenceCostsOnly' },
        { title: 'MMI Made Offer', data: 'treatmentCostsOS' },
        { title: 'Addition Policy Number 1', data: 'additionalPolicyNumberList', render: function (data) { return data.length >= 1 ? data[0] : "" } },
        { title: 'Addition Policy Number 2', data: 'additionalPolicyNumberList', render: function (data) { return data.length >= 2 ? data[1] : "" } },
        { title: 'Addition Policy Number 3', data: 'additionalPolicyNumberList', render: function (data) { return data.length >= 3 ? data[2] : "" } },
        { title: 'Addition Policy Number 4', data: 'additionalPolicyNumberList', render: function (data) { return data.length >= 4 ? data[3] : "" } },
        { title: 'Addition Policy Number 5', data: 'additionalPolicyNumberList', render: function (data) { return data.length == 5 ? data[4] : data.length > 5 ? data.slice(4).join(', ') : "" } },
        { title: 'Action', data: null, sortable: false }];
    }
    else {
      this.displayedColumns = [
        { title: 'Claim Id', data: 'claimId' },
        { title: 'Policy Id', data: 'policyId' },
        { title: 'MMI Claim Reference', data: 'mmiClaimsReference' },
        { title: 'Claimant Name', data: 'claimantName' },

        { title: 'Occupation', data: 'occupation' },
        { title: 'Claimant Solicitor', data: 'claimantSolicitor' },


        { title: 'Insured Title', data: 'insuredTitle' },
        { title: 'Handling Organisation Claim Ref', data: 'claimReference' },

        { title: 'Next Key Date', data: 'nextKeyDateSelected' },
        { title: 'Next Key Date Diary', data: 'nextKeyDateDiary', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },

        { title: 'Policy Number', data: 'policyNumber' },
        { title: 'Policyholder Name', data: 'creditorName' },
        { title: 'Policyholder ID', data: 'creditorId' },
        { title: 'Claim Type', data: 'claimType' },

        { title: 'MMI Valuation', data: 'mmiValuation', render: $.fn.dataTable.render.number(',', '.', 2, '£') },

        { title: 'MMI Paid', data: 'totalPayments', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
        { title: 'MMI Reserve', data: 'totalReserve', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
        { title: 'MMI Incurred', data: 'totalIncurred', render: $.fn.dataTable.render.number(',', '.', 2, '£') },
        { title: 'Reserve Last Reviewed', data: 'reserveLastReviewed', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'Diary Date', data: 'diaryDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'Claim Status', data: 'claimStatus' },
        { title: 'Linked References', data: 'linkedReferences' },
        { title: 'Line Of Business', data: 'lineofBusiness' },
        { title: 'Handling Organization', data: 'handlingOrganisation' },
        { title: 'Claim Handler', data: 'claimHandler' },
        { title: 'Claimant DOB', data: 'claimantDOB', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'MMI Review Date', data: 'mmiReviewDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'MMI Key Contact', data: 'mmiKeyContact' },
       /* { title: 'MMI Supervisor', data: 'mmiSupervisor' },*/
        { title: 'Claimant NINO', data: 'claimantNINO' },
        { title: 'Location', data: 'location', render: function (data) { return data == null ? "" : data.substr(1) } },
        { title: 'Perpetrator', data: 'perpetrator', render: function (data) { return data == null ? "" : data.substr(1) } },
        { title: 'Open Date', data: 'openDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'Settled Date', data: 'settledDate', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'Date Last Re-Opened', data: 'dateLastReopened', type: 'date', render: function (data) { return data == null ? "" : moment(data).format('DD/MM/YYYY') } },
        { title: 'DA Status', data: 'daStatus' },
        { title: 'Charging Rate', data: 'chargingRate' },
        { title: 'Litigated Claim', data: 'litigated' },
        { title: 'Contribution Claim', data: 'contributionClaim' },

        { title: 'Jurisdiction', data: 'jurisdiction' },
        { title: 'Cape Products', data: 'capeProduct' },
        { title: 'Provisional Damages Settlement', data: 'provisionalDamagesSettlement' },

        { title: 'Claim Stage', data: 'defenceCostsOnly' },
        { title: 'MMI Made Offer', data: 'treatmentCostsOS' },
        { title: 'Addition Policy Number 1', data: 'additionalPolicyNumberList', render: function (data) { return data.length >= 1 ? data[0] : "" } },
        { title: 'Addition Policy Number 2', data: 'additionalPolicyNumberList', render: function (data) { return data.length >= 2 ? data[1] : "" } },
        { title: 'Addition Policy Number 3', data: 'additionalPolicyNumberList', render: function (data) { return data.length >= 3 ? data[2] : "" } },
        { title: 'Addition Policy Number 4', data: 'additionalPolicyNumberList', render: function (data) { return data.length >= 4 ? data[3] : "" } },
        { title: 'Addition Policy Number 5', data: 'additionalPolicyNumberList', render: function (data) { return data.length == 5 ? data[4] : data.length > 5 ? data.slice(4).join(', ') : "" } },
        { title: 'Action', data: null, sortable: false }];
    }

    this.initialiseDataTable();
    

    this.service.getAllHandlingOrganisations()
      .subscribe((reponse: any) => {
        this.handlingOrganisations = reponse;
        console.log("Organisations");
        console.log(this.handlingOrganisations);
      });
    //this.LineofBusinessLookup = this.lookupService.getLookupsByTypeId(12);
    this.loadLOB();
    this.ClaimStatusLookups = this.lookupService.getLookupsByTypeId(3);
    this.ClaimTypeLookups = this.lookupService.getLookupsByTypeId(1);
    this.getMmiClaimHandlers(enumHandlingOrganization.MMI);
    this.locationsLookup = this.lookupService.getLookupsByTypeId(22);
    this.perpetratorsLookup = this.lookupService.getLookupsByTypeId(29);
    this.ClaimStageLookups = this.lookupService.getLookupsByTypeId(66);
    this.nextKeyDates = this.lookupService.getLookupsByTypeId(67);
    this.occupationTypeLookup = this.lookupService.getLookupsByTypeId(18);
    this.ClaimantSolicitorsLookup = this.lookupService.getLookupsByTypeId(44);
    this.capeProducts = this.lookupService.getLookupsByTypeId(8);
    this.mmiSupervisorLookup = this.lookupService.getLookupsByTypeId(73);

    var searchCriteria = this.storageService.retrieve("claimSearchCriteria");
    if (searchCriteria) {
      var that = this;
      this.searchClaimVM = searchCriteria;
      this.onOrganisationChange();
      this.dtOptions.displayStart = this.searchClaimVM.pageIndex * this.searchClaimVM.pageSize;
      this.dtOptions.order = [[_.findIndex( this.displayedColumns, function (o) { return o.data == that.searchClaimVM.sortBy; }), , this.searchClaimVM.sortOrder]]
      this.dtOptions.pageLength = this.searchClaimVM.pageSize;
      setTimeout(r => { this.searchClaim() }, 0);
    } else {
      this.isInitialised = true;
      if (!this.isMMIUser) {
        this.getClaimHandlers(this.searchClaimVM.handlingOrganisation);
      }
      else {
        this.getClaimHandlers(0);
      }
    }

  }

  initialiseDataTable(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      columns: this.displayedColumns,
      scrollY: '50vh',
      scrollX: true,
      scrollCollapse: true,
      //initComplete: function (settings, json) {
      //  $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
      //},
      //drawCallback: function (settings, json) {
      //  $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
      //},
      //fixedColumns: {
      //  leftColumns: 1,
      //  rightColumns: 1
      //},
      serverSide: true,
      processing: true,
      searching: false,
      // Declare the use of the extension in the dom parameter
      dom: 'Blfrtip',
      // Configure the buttons
      buttons: [
        {
          extend: 'excelHtml5',
          text: '<i class="fa fa-file-excel fa-lg"></i>',
          titleAttr: 'Excel',
          tag: '',
          action: this.newExportAction,
          messageTop: "Search Claim Result",
          title: "Claims",
          filename: function () { return "Claims" + Math.random(); },
          exportOptions: {
            columns: this.isHiddenMMISupervisor == false ? [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48] : [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47]
          }
        }
      ],

      columnDefs: this.isHiddenMMISupervisor == false ? [
        {
          targets: -1,
          className: "sticky-column-right",
          render: function (data) {
            //data = '<div class="btnsearch"><a class="btn btn-primary btn-sm" href="/claims/' + data["policyId"] + '/' + data["claimId"] +'"><i class="fa fa-eye"></i></a></div>';
            data = '<button class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>';
            return data;

          }
        },
        {
          targets: 0,
          visible: false,
          searchable: false
        },
        {
          targets: 1,
          visible: false,
          searchable: false
        },
        {
          targets: 2,
          className: "sticky-column-left",
        },
        {
          targets: 3,
          visible: true,
          searchable: true
        },
        {
          targets: 4,
          visible: false,
          searchable: false
        },
        {
          targets: 5,
          visible: false,
          searchable: false
        },
        {
          targets: 6,
          visible: true,
          searchable: true
        },
        {
          targets: 7,
          visible: true,
          searchable: true
        },
        {
          targets: 8,
          visible: false,
          searchable: false
        },
        {
          targets: 9,
          visible: false,
          searchable: false
        },
        {
          targets: 10,
          visible: true,
          searchable: true
        },

        {
          targets: 11,
          visible: true,
          searchable: true
        },

        {
          targets: 12,
          visible: true,
          searchable: true
        },

        {
          targets: 13,
          visible: true,
          searchable: true
        },

        {
          targets: 14,
          visible: false,
          searchable: false
        },

        {
          targets: 15,
          visible: true,
          searchable: true
        },

        {
          targets: 16,
          visible: true,
          searchable: true
        },
        {
          targets: 17,
          visible: true,
          searchable: true
        },
        {
          targets: 18,
          visible: true,
          searchable: true
        }
        ,
        {
          targets: 19,
          visible: true,
          searchable: true
        }
        ,
        {
          targets: 20,
          visible: true,
          searchable: true
        }
        ,
        {
          targets: 21,
          visible: true,
          searchable: true
        },
        {
          targets: 22,
          visible: true,
          searchable: true
        },
        {
          targets: 23,
          visible: false,
          searchable: false
        },
        {
          targets: 24,
          visible: false,
          searchable: false
        },
        {
          targets: 25,
          visible: false,
          searchable: false
        },
        {
          targets: 26,
          visible: false,
          searchable: false
        },
        {
          targets: 27,
          visible: false,
          searchable: false
        },
        {
          targets: 28,
          visible: false,
          searchable: false
        },
        {
          targets: 29,
          visible: false,
          searchable: false
        },
        {
          targets: 30,
          visible: false,
          searchable: false
        },
        {
          targets: 31,
          visible: false,
          searchable: false
        },
        {
          targets: 32,
          visible: false,
          searchable: false
        },
        {
          targets: 33,
          visible: false,
          searchable: false
        },
        {
          targets: 34,
          visible: false,
          searchable: false
        },
        {
          targets: 35,
          visible: false,
          searchable: false
        },
        {
          targets: 36,
          visible: false,
          searchable: false
        },
        {
          targets: 37,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 38,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 39,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 40,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 41,
          visible: false,
          searchable: false
        },
        {
          targets: 42,
          visible: false,
          searchable: false
        },
        {
          targets: 43,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 44,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 45,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 46,
          visible: false,
          searchable: false
        },
        {
          targets: 47,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 48,
          visible: false,
          searchable: false
        }
      ] : [
        {
          targets: -1,
          className: "sticky-column-right",
          render: function (data) {
            //data = '<div class="btnsearch"><a class="btn btn-primary btn-sm" href="/claims/' + data["policyId"] + '/' + data["claimId"] +'"><i class="fa fa-eye"></i></a></div>';
            data = '<button class="btn btn-primary btn-sm"><i class="fa fa-eye"></i></button>';
            return data;

          }
        },
        {
          targets: 0,
          visible: false,
          searchable: false
        },
        {
          targets: 1,
          visible: false,
          searchable: false
        },
        {
          targets: 2,
          className: "sticky-column-left",
        },
        {
          targets: 3,
          visible: true,
          searchable: true
        },
        {
          targets: 4,
          visible: false,
          searchable: false
        },
        {
          targets: 5,
          visible: false,
          searchable: false
        },
        {
          targets: 6,
          visible: true,
          searchable: true
        },
        {
          targets: 7,
          visible: true,
          searchable: true
        },
        {
          targets: 8,
          visible: false,
          searchable: false
        },
        {
          targets: 9,
          visible: false,
          searchable: false
        },
        {
          targets: 10,
          visible: true,
          searchable: true
        },

        {
          targets: 11,
          visible: true,
          searchable: true
        },

        {
          targets: 12,
          visible: true,
          searchable: true
        },

        {
          targets: 13,
          visible: true,
          searchable: true
        },

        {
          targets: 14,
          visible: false,
          searchable: false
        },

        {
          targets: 15,
          visible: true,
          searchable: true
        },

        {
          targets: 16,
          visible: true,
          searchable: true
        },
        {
          targets: 17,
          visible: true,
          searchable: true
        },
        {
          targets: 18,
          visible: true,
          searchable: true
        }
        ,
        {
          targets: 19,
          visible: true,
          searchable: true
        }
        ,
        {
          targets: 20,
          visible: true,
          searchable: true
        }
        ,
        {
          targets: 21,
          visible: true,
          searchable: true
        },
        {
          targets: 22,
          visible: true,
          searchable: true
        },
        {
          targets: 23,
          visible: false,
          searchable: false
        },
        {
          targets: 24,
          visible: false,
          searchable: false
        },
        {
          targets: 25,
          visible: false,
          searchable: false
        },
        {
          targets: 26,
          visible: false,
          searchable: false
        },
        {
          targets: 27,
          visible: false,
          searchable: false
        },
        {
          targets: 28,
          visible: false,
          searchable: false
        },
        {
          targets: 29,
          visible: false,
          searchable: false
        },
        {
          targets: 30,
          visible: false,
          searchable: false
        },
        {
          targets: 31,
          visible: false,
          searchable: false
        },
        {
          targets: 32,
          visible: false,
          searchable: false
        },
        {
          targets: 33,
          visible: false,
          searchable: false
        },
        {
          targets: 34,
          visible: false,
          searchable: false
        },
        {
          targets: 35,
          visible: false,
          searchable: false
        },
        {
          targets: 36,
          visible: false,
          searchable: false
        },
        {
          targets: 37,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 38,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 39,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 40,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 41,
          visible: false,
          searchable: false
        },
        {
          targets: 42,
          visible: false,
          searchable: false
        },
        {
          targets: 43,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 44,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 45,
          visible: false,
          searchable: false
        }
        ,
        {
          targets: 46,
          visible: false,
          searchable: false
          }
          ,
        {
          targets: 47,
          visible: false,
          searchable: false
        }
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        $('.btn-primary', row).unbind('click');
        $('.btn-primary', row).bind('click', () => {
          self.navigateToClaims(data);
        });

        return row;
      },

      ajax: (dataTablesParameters: any, callback) => {
        var self = this;
        if (this.isInitialised) {
          this.searchClaimVM.lineofBusiness = _.map(this.searchClaimVM.lineofBusinesses, function (o: any) { return o.id })
          this.searchClaimVM.claimStatus = _.map(this.searchClaimVM.claimStatuses, function (o: any) { return o.id })
          this.searchClaimVM.sortBy = dataTablesParameters.columns[dataTablesParameters.order[0].column].data;
          this.searchClaimVM.sortOrder = dataTablesParameters.order[0].dir;
          this.searchClaimVM.pageIndex = dataTablesParameters.start > 0 ? parseInt(dataTablesParameters.start) / parseInt(dataTablesParameters.length) : 0;
          this.searchClaimVM.pageSize = dataTablesParameters.length;
          this.searchClaimVM.dairyDate = this.searchClaimVM.dairyCalendarDate != null ? !(this.isIsoDate(this.searchClaimVM.dairyCalendarDate)) ? this.searchClaimVM.dairyCalendarDate.toUTCString() : this.searchClaimVM.dairyCalendarDate : null;
          this.searchClaimVM.dob = this.searchClaimVM.dobCalendar != null ? !(this.isIsoDate(this.searchClaimVM.dobCalendar)) ? this.searchClaimVM.dobCalendar.toUTCString() : this.searchClaimVM.dobCalendar : null;
          this.searchClaimVM.reservedLastReview = this.searchClaimVM.reservedLastReviewCalendar != null ? !(this.isIsoDate(this.searchClaimVM.reservedLastReviewCalendar)) ? this.searchClaimVM.reservedLastReviewCalendar.toUTCString() : this.searchClaimVM.reservedLastReviewCalendar : null;
          this.searchClaimVM.mmiReviewDate = (this.searchClaimVM.mmiReviewDateCalendar != null) ? !(this.isIsoDate(this.searchClaimVM.mmiReviewDateCalendar)) ? this.searchClaimVM.mmiReviewDateCalendar.toUTCString() : this.searchClaimVM.mmiReviewDateCalendar : null;
          this.searchClaimVM.mmiKeyContact = _.map(this.searchClaimVM.mmiKeyContactHandlers, function (o: any) { return o.id });
          this.searchClaimVM.location = _.map(this.searchClaimVM.locations, function (o: any) { return o.id });
          this.searchClaimVM.perpetrator = _.map(this.searchClaimVM.perpetrators, function (o: any) { return o.id });
        }
        else {
          this.isInitialised = true;
        }
        this.getClaims(this.searchClaimVM).then(resp => {
          callback({
            page: that.paginationInfo.actualPage,
            pages: that.paginationInfo.totalPages,
            recordsTotal: that.paginationInfo.totalItems,
            recordsFiltered: that.paginationInfo.totalItems,
            data: that.searchClaimResult
          });
        });
      }
    };

  }

  isIsoDate(str) {
    if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
    var d = new Date(str);
    return d.toISOString() === str;
  }

  navigateToClaims(data) {
    this.router.navigate(['/claims/' + data.policyId + '/' + data.claimId]);
    //if (this.loggedInUserOrganisationId == 1) {

    //  this.router.navigate(['/claims/' + data.policyId + '/' + data.claimId])
    //}
    //else
    //{
    //  this.service.checkClaimAccessiblity(data.claimId, this.loggedInUserOrganisationId)
    //    .subscribe((response: any) => {
    //      if (response) {
    //        this.router.navigate(['/claims/' + data.policyId + '/' + data.claimId])
    //      }
    //      else {
    //        this.router.navigate(['/access-denied']);
    //      }
    //    });
    //}
  }
  convertStringToDate(dateString) {
    if (dateString == undefined || dateString == "")
      return null;
    else
      return new Date(dateString);
  }
  searchClaim(): void {
    //For LookUp Reference

    //console.log("looks:");
    //console.log(lookups);
    this.isHidden = false;

    if (this.isMMIClaimAndClaimFinanceSuperUser == false) {
      this.isHiddenMMISupervisor = true;
    }

    if (this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
    else {
      this.dtTrigger.next();
    }
    setTimeout(() => this.btnSearchClaims.nativeElement.focus(), 0);
  }
  enterKeydownOnForm(event): void {
    event.preventDefault();
    this.searchClaim();
  };

  openForpolicy(policyId) {
    this.router.navigate(['/policy-holder/edit/' + policyId]);
  }
  onOrganisationChange() {
    if (!this.isMMIUser) {
      this.searchClaimVM.handlingOrganisation = this.loggedInUserOrganisationId;
    }
    this.getClaimHandlers(this.searchClaimVM.handlingOrganisation);
  }
  getClaimHandlers(organisationId) {
    this.service.getClaimHandlers(organisationId)
      .subscribe((reponse: any) => {
        this.claimHandlers = reponse;
        if (this.searchClaimVM.claimsHandler == 0)
          this.searchClaimVM.claimsHandler = 0
      });
  }

  getMmiClaimHandlers(organisationId) {
    this.service.getClaimHandlers(organisationId)
      .subscribe((response: any) => {
        if (response != null) {
          this.mmiClaimHandlers = response;
        }
      });
  }

  resetform() {
    /*debugger;*/
    this.searchClaimVM = new ISearchClaimVM();
    this.isHidden = true;

    if (this.isMMIClaimAndClaimFinanceSuperUser == false) {
      this.isHiddenMMISupervisor = true;
    }

    if (!this.isMMIUser) {
      this.searchClaimVM.handlingOrganisation = this.loggedInUserOrganisationId;
    }
  }
  getClaims(filterCriteria: ISearchClaimVM): Promise<any> {
    this.errorReceived = false;
    var claimsData: any;

    this.storageService.store("claimSearchCriteria", this.searchClaimVM);
    //if (this.searchClaimVM.mmiKeyContact == null) {
    //  this.searchClaimVM.mmiKeyContact = "";
    //}
    var promise = new Promise<void>((resolve, reject) => {
      this.service.getClaims(filterCriteria)
        .pipe(catchError((err) => this.handleError(err)))
        .subscribe(claims => {
          this.searchClaimResult = claims.serachClaimResultItems;
          this.paginationInfo = claims.paginationInfo;
          resolve();
        });
    });
    //if (this.searchClaimVM.mmiKeyContact == "") {
    //  this.searchClaimVM.mmiKeyContact = null;
    //}
    return promise;
  }

  private handleError(error: any) {
    this.errorReceived = true;
    return Observable.throw(error);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }


  public oldExportAction(self, e, dt, button, config) {
    if (button[0].className.indexOf('buttons-excel') >= 0) {
      //this.datatableElement.dtInstance.
      var buttons: any = ($.fn.dataTable.ext as any).buttons;
      if (buttons.excelHtml5.available(dt, config)) {
        buttons.excelHtml5.action.call(self, e, dt, button, config);
      }
      else {
        buttons.excelFlash.action.call(self, e, dt, button, config);
      }
    } else if (button[0].className.indexOf('buttons-print') >= 0) {
      buttons.print.action(e, dt, button, config);
    }
  }

  public newExportAction(e, dt, button, config) {
    var self = this;
    var oldStart = dt.settings()[0]._iDisplayStart;
    console.log("buttons");
    //console.log($.fn.dataTable.ext.buttons);
    dt.one('preXhr', function (e, s, data) {
      // Just this once, load all data from the server...
      data.start = 0;
      data.length = 30000;

      dt.one('preDraw', function (e, settings) {
        // Call the original action function
        oldExportAction(self, e, dt, button, config);

        dt.one('preXhr', function (e, s, data) {
          // DataTables thinks the first item displayed is index 0, but we're not drawing that.
          // Set the property to what it was before exporting.
          settings._iDisplayStart = oldStart;
          data.start = oldStart;
        });

        // Reload the grid with the original page. Otherwise, API functions like table.cell(this) don't work properly.
        setTimeout(dt.ajax.reload, 0);

        // Prevent rendering of the full data to the DOM
        return false;
      });
    });

    // Requery the server with the new one-time export settings
    dt.ajax.reload();

    function oldExportAction(self, e, dt, button, config) {
      if (button[0].className.indexOf('buttons-excel') >= 0) {
        //this.datatableElement.dtInstance.
        var buttons: any = ($.fn.dataTable.ext as any).buttons;
        if (buttons.excelHtml5.available(dt, config)) {
          buttons.excelHtml5.action.call(self, e, dt, button, config);
        }
        else {
          buttons.excelFlash.action.call(self, e, dt, button, config);
        }
      } else if (button[0].className.indexOf('buttons-print') >= 0) {
        buttons.print.action(e, dt, button, config);
      }
    }
  };

  searchTitle = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((searchText) => this.service.searchTitle(searchText)),
      tap(() => this.searching = false)
    )

  searchPerpetrators = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((searchText) => this.service.searchPerpetrators(searchText)),
      tap(() => this.searching = false)
    )

  get getLocationItems() {
    return this.locationsLookup.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }

  get getPerpetratorItems() {
    return this.perpetratorsLookup.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }

  loadLOB() {
    let ELandTP = this.lookupService.getLookupsByTypeId(12).filter(item => { return item.text.toUpperCase() == "EL" || item.text.toUpperCase() == "TP" });
    this.LineofBusinessLookup = this.lookupService.getLookupsByTypeId(12).filter(item => { return item.text.toUpperCase() != "EL" && item.text.toUpperCase() != "TP" });
    this.LineofBusinessLookup.sort((a, b) => (a.text > b.text) ? 1 : -1);
    this.LineofBusinessLookup.unshift(ELandTP[0], ELandTP[1]);
  }

}
