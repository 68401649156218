export class ISearchClaimVM {
  //position: number;
  claimReference: string;
  nino: string;
  policyNumber: string;
  insuredTitle: string;
  dairyCalendarDate: Date;
  dairyDate: any;
  claimType: number;
  surname: string;
  lineofBusiness: any[];
  lineofBusinesses: [];
  claimStatus: any[];
  claimStatuses: [];
  creditorID: string;
  firstName: string;
  creditorName: string;
  handlingOrganisation: number;
  dobCalendar: Date;
  dob: any;
  reservedLastReviewCalendar: Date;
  reservedLastReview: any;
  claimsHandler: number;
  pageIndex: number;
  pageSize: number;
  sortBy?: string;
  sortOrder?: string;
  mmiClaimsReference: string;
  linkedReferences: string;
  mmiReviewDateCalendar: Date;
  mmiReviewDate: any;
  mmiKeyContact: any[];
  mmiKeyContactHandlers: [];
  //perpetrator: string;
  location: any[];
  locations: [];
  perpetrator: any[];
  perpetrators: [];
  contributionClaim: number;
  defenceCostsOnly: number;
  treatmentCostsOS: number;
  nextKeyDateID: number;
  occupation: number;
  claimantSolicitor: number;
  capeProductID: number;
  mmiSupervisor: number;

  constructor() {
    this.claimsHandler = 0;
    this.claimType = 0;
    this.handlingOrganisation = 0;
    this.contributionClaim = null;
    this.defenceCostsOnly = null;
    this.treatmentCostsOS = null;
    this.nextKeyDateID = null;
    this.occupation = null;
    this.claimantSolicitor = null;
    this.capeProductID = null;
    this.mmiSupervisor = null;
  }
}
